@use 'th-common/styles/index';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: index.$padding-md;
}

.loginSection {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: index.$spacing-md;
  width: 22rem;
}

.logo {
  margin: 0 auto;
}

.center {
  color: var(--color-on-surf);
  text-align: center;
}
