@use 'th-common/styles/index';

.contentGrid {
  position: relative;
  display: grid;
  grid-template-rows: minmax(2.5rem, min-content) minmax(0, 1fr) auto;
  gap: index.$spacing-sm;
  height: 100%;
  padding-bottom: 0 !important;
}
