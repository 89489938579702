@use 'th-common/styles/index';

.fleetStatus {
  position: relative;
  display: grid;
  grid-template-rows: 2.5rem auto minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
  gap: index.$spacing-sm;
  height: 100%;
  padding-bottom: 0 !important;

  @include index.breakpoint(index.$screen-medium) {
    grid-template-rows: 2.5rem auto minmax(0, 1fr) auto;
  }
}

.header {
  display: flex;
  gap: index.$spacing-md;
  align-items: center;
  justify-content: space-between;
  padding-right: index.$padding-xs;
}

.headerFilter {
  display: none;

  @include index.breakpoint(index.$screen-medium) {
    display: block;
  }
}

.compactFilter {
  display: block;

  @include index.breakpoint(index.$screen-medium) {
    display: none;
  }
}

.headerTitleAndFilters {
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, auto);
  gap: index.$spacing-md;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
}

.page {
  &_details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
