@use 'th-common/styles/index';

.panel {
  position: relative;
  display: grid;
  grid-template-rows: 2.5rem minmax(0, 1fr);
  gap: 1rem;
  min-width: 19.75rem;
  height: 100%;
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-on-surf);
}

.panelsWrapper {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, min-content));
  gap: 1rem;

  &:has(> #gpsDataPanel) {
    grid-template-rows: repeat(3, minmax(0, min-content));

  }
}
