@use 'th-common/styles/index';

.itemWrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 6rem 11rem 1fr;
  gap: index.$spacing-sm;
  align-items: center;
  padding: index.$padding-xxs index.$padding-xxs index.$padding-xxs
    index.$padding-sm;
  border-top: 0.5px solid var(--color-outline-variant);

  &_error {
    color: var(--color-on-error-container);
    background-color: var(--color-error-container);
  }

  &:first-child {
    border-top: none;
  }
}
