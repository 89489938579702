@use 'th-common/styles/index';

.fleetsLoader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.table {
  display: block;
  height: 100%;
  padding-right: index.$padding-xxs;
  overflow: auto;

  &_empty {
    height: 100%;
  }
}
