.appVersion {
  width: 100%;
  text-align: center;
}

.link {
  color: var(--color-primary);
  font-weight: bold;
  text-decoration: none;
}
