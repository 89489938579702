@use 'th-common/styles/index';

.statusTab {
  position: relative;
  margin: 0 -#{index.$spacing-xxs} 0 -#{index.$spacing-sm};
}

.section {
  padding: index.$spacing-md index.$spacing-sm;
  border-top: solid 1px var(--color-outline-variant);

  &:first-child {
    padding-top: index.$spacing-sm;
    border-top: none;
  }
}

.sectionTitle {
  margin-bottom: index.$spacing-xs !important;
}

.statusTabContent {
  padding: 0 !important;
}
