@use 'th-common/styles/index';

.grid {
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(2, 1fr);
  gap: index.$spacing-md;
  max-height: 100%;
  padding-right: index.$padding-xxs;
  overflow: auto;

  @include index.breakpoint(index.$screen-medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include index.breakpoint(index.$screen-large) {
    grid-template-columns: repeat(4, 1fr);
  }

  &_statusInfo {
    grid-template-columns: repeat(2, 1fr);
  }

  &_empty {
    grid-template-columns: 1fr;
  }
}
