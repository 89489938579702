@use 'th-common/styles/index';

.videoQueryInfoWrapper {
  position: relative;
  display: grid;
  grid-template-rows: 2.5rem min-content minmax(0, 1fr) auto;
}

.videoQueryInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
