@use 'th-common/styles/index';

.columnsSelect {
  position: relative;
}

.columnsButton {
  position: relative;
  height: 2.25rem;
  padding: index.$padding-xxs index.$padding-lg index.$padding-xxs
    index.$padding-sm !important;
  color: var(--color-on-surf-variant) !important;
  border: 1px solid var(--color-outline) !important;

  &_active {
    border: 2px solid var(--color-primary) !important;
  }
}

.columnsButtonText {
  line-height: 1.125rem;
}

.columnsButtonIcon {
  position: absolute;
  top: 5px;
  right: 7px;
  color: rgba(0, 0, 0, 0.54);
}
