$margin-compact: 1rem;
$margin: 1.5rem;

$spacer: 1.5rem;

$padding-none: 0;
$padding-petite: 0.25rem;
$padding-xxs: 0.5rem;
$padding-xs: 0.75rem;
$padding-sm: 1rem;
$padding-md: 1.5rem;
$padding-lg: 2rem;
$padding-xl: 2.5rem;
$padding-xxl: 3rem;

$spacing-none: 0;
$spacing-xxs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 1.5rem;
$spacing-lg: 2rem;
$spacing-xl: 2.5rem;
$spacing-xxl: 3rem;

$radius-round: 18.75rem;
$radius-xl: 1.75rem;
$radius-lg: 1rem;
$radius-md: 0.75rem;
$radius-sm: 0.5rem;
$radius-xs: 0.25rem;
$radius-none: 0;
