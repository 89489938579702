.activeButton {
  background-color: var(--color-secondary-container) !important;
}

.buttonLabel {
  padding: 0 8px;
}

.button {
  color: var(--dropdown-button-color, var(--color-on-surf-variant)) !important;

  &:hover {
    background-color: var(--color-surf-container-highest);
  }
}

.footer {
  padding: 0 var(--padding-sm);
}
