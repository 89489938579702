@use 'th-common/styles/index';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerWithFilters {
  display: flex;
  gap: index.$spacing-md;
  align-items: center;
}

.headerControls {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}

.subHeader {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}
