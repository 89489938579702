@use 'th-common/styles/index';

.rightControls {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}

.searchInput {
  width: 0;
  height: 0;
  visibility: hidden;
  transition: width 0.3s ease-in-out;

  &_active {
    height: auto;
    visibility: visible;
  }

  &_focused {
    width: 300px;
    min-width: 300px;
  }
}
