@use 'th-common/styles/index';

.formControl {
  width: 100%;
}

.label {
  margin-bottom: var(--th-form-radio-group-label-margin-bottom, 0);
  padding-left: var(--th-form-radio-group-label-padding-left, 0);
  font-weight: var(--th-radio-group-font-weight, inherit);
  font-size: var(--th-radio-group-font-size, inherit);
  font-style: var(--th-radio-group-font-style, inherit);
  line-height: var(--th-radio-group-line-height, inherit);
  text-transform: var(--th-radio-group-text-transform);
}

.container {
  display: flex;
  flex-direction: var(--th-radio-group-container-flex-direction, column);
  flex-wrap: var(--th-radio-group-container-flex-wrap, wrap);
  box-sizing: border-box;
  column-gap: var(--th-radio-group-container-column-gap, 0);
  row-gap: var(--th-radio-group-container-row-gap, 0);
}
