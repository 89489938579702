@use 'th-common/styles/index';

.selectedVideoQueryWrapper {
  display: flex;
  flex-direction: column;
  gap: index.$spacing-sm;
  height: 100%;
  overflow: auto;
}

.selectedVideoQueryBox {
  margin: 0 -#{index.$spacing-xxs} 0 -#{index.$spacing-sm};
}
