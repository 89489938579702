@use 'th-common/styles/index';

.header {
  color: var(--color-on-surf-variant) !important;
  background-color: var(--color-surf-container-low) !important;
  box-shadow: none !important;
  transition: transform 1s !important;
}

.container {
  padding-top: index.$padding-md;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: var(--color-surf-container-low) !important;
}

.toolbar {
  justify-content: space-between;
  min-height: 2.25rem !important;
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  background-color: var(--color-surf-container-low) !important;
  border-radius: index.$radius-lg;
}

.logo {
  color: var(--color-on-secondary-container);
  cursor: pointer;
}

.headerDrawerClosed {
  transform: translateY(-4.25rem);
}
