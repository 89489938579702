@use 'th-common/styles/index';

$height: 4rem;

.headerDrawerContainer {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: $height;
  background: var(--color-surf-container-low, #f5f2fa);
  box-shadow:
    0 1px 4px 0 rgba(0, 0, 0, 0.3),
    0 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.headerDrawerItem {
  position: absolute;
  top: $height;
  left: calc(50% - 2.25rem);
  display: flex;
  align-items: center;
  width: 4.5rem;
  padding: 0 var(--layout-padding-md, 1.5rem);
  background: var(--color-surf-container-low, #f5f2fa);
  border-radius: 0 0 var(--radius-rad-sm, 8px) var(--radius-rad-sm, 8px);
  cursor: pointer;
  filter: drop-shadow(0 6px 3px rgba(0, 0, 0, 0.1));
}
