@use 'th-common/styles/index';

.gpsDataPanel {
  position: relative;
  height: 100%;
}

.mapWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-bottom-right-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
}

.mapDetailsWrapper {
  margin: 0 -16px -16px;
}

.mapDetails {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.headerActions {
  display: flex;
  gap: 0.125rem;
  align-items: center;
}

.headerActionToggle {
  padding: var(--padding-xxs) !important;
  border: 0 !important;
}

.mapSettings {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  padding: 0 var(--padding-xxs);
  color: var(--color-on-surf-variant);
}
