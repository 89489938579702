@use 'th-common/styles/index';

.content {
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content minmax(0, 1fr);
  height: 100%;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  gap: index.$spacing-sm;
  align-items: center;
  margin-top: index.$spacing-sm;
  overflow: hidden;
  color: var(--color-on-surf-variant);
}
