@use 'th-common/styles/index';

.markedVideoPanel {
  --_header-height: 6rem;

  position: relative;
  display: grid;
  grid-template-rows: var(--_header-height) minmax(0, 1fr) auto;
  gap: index.$spacing-sm;
  height: 100%;
  padding-bottom: 0 !important;
}

.headerContainer {
  display: grid;
  grid-template-rows: 2.5rem 2.5rem;
  gap: index.$spacing-xs;
  width: 100%;
  height: var(--_header-height);
}

.headerRow {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerRowRight {
  display: flex;
  gap: index.$spacing-sm;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.footer {
  align-self: flex-end;
}
