@use 'th-common/styles/index';

$root: '.card';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: index.$spacing-xs;
  justify-content: space-between;
  min-height: 6.5rem;
  padding: index.$padding-sm;
  overflow: hidden;
  color: var(--color-on-surf-variant);
  background: var(--card-background, var(--color-surf));
  border-radius: index.$radius-md;
  cursor: default;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 0.8rem;
    border-top-left-radius: index.$radius-md;
    border-top-right-radius: index.$radius-md;
    box-shadow: none;
    content: '';
  }

  &_clickable {
    cursor: pointer;
  }

  &_selected,
  &_active,
  &:hover:not(#{$root}_noHover) {
    --_card-border-color: var(--card-border-color, var(--color-primary));

    box-shadow: inset 0 0 0 var(--card-border-size, 1px)
      var(--_card-border-color);

    &::before {
      border-top: var(--card-border-size, 1px) solid var(--_card-border-color);
      border-right: var(--card-border-size, 1px) solid var(--_card-border-color);
      border-left: var(--card-border-size, 1px) solid var(--_card-border-color);
    }
  }

  &_active,
  &:hover:not(#{$root}_noHover) {
    --card-background: var(--color-surf-container-high);

    .cardProgressBar {
      top: 1px;
      height: 0.3125rem;
    }
  }

  &_selected {
    --card-border-size: 2px;

    &:not(#{$root}_critical) {
      --card-background: var(--color-surf-container-highest);

      &:hover:not(#{$root}_noHover) {
        --card-background: var(--color-surf-container-highest);
      }
    }
  }

  &_warning {
    --card-border-color: var(--color-warning);
  }

  &_critical {
    --card-background: var(--color-error-container);
    --card-border-color: var(--color-on-error-container);
    --card-header-title-color: var(--color-on-error-container);

    color: var(--color-on-error-container);

    &:hover:not(#{$root}_noHover) {
      --card-background: var(--color-error-container);
    }
  }
}

.cardHeader {
  display: flex;
  gap: index.$padding-petite;
  align-items: center;
  justify-content: space-between;
}

.cardHeaderTitle {
  display: flex;
  gap: index.$padding-petite;
  align-items: center;
  color: var(--card-header-title-color, var(--color-on-surf));
}

.cardHeaderTitleIcon {
  flex: 0 0 var(--card-header-title-icon-size, 1.5rem);
  width: var(--card-header-title-icon-size, 1.5rem);
  height: var(--card-header-title-icon-size, 1.5rem);
}

.cardFooter {
  display: flex;
  gap: var(--card-footer-gap, index.$padding-petite);
  align-items: center;
  justify-content: space-between;
}

.dotsMenu {
  margin-right: -#{index.$spacing-xs};

  #{$root}:hover & {
    --dots-menu-visibility: visible;
  }
}

.cardProgressBar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 0.375rem;
  background-color: var(--color-outline-variant);
}

