@use 'th-common/styles/index';

.wrapper {
  display: flex;
  gap: index.$spacing-md;
  align-items: center;
  justify-content: space-between;
  padding-right: index.$padding-xs;
}

.rightSideControls {
  display: flex;
  gap: index.$spacing-md;
  align-items: center;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: index.$spacing-md;
  align-items: center;

  > * {
    width: min-content !important;
  }
}
