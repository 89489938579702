@use 'th-common/styles/index';

.grid {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 1fr 0;
  width: 100%;
  height: 100%;
  transition: 200ms;

  &_panel {
    grid-template-columns: minmax(0, 1fr) 25rem;
    gap: 1.5rem;
  }
}

.modal {
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content minmax(0, 1fr) auto;
  gap: 1.125rem;
  height: 100%;
}
