@use 'th-common/styles/index';

.loaderWrapper {
  position: absolute;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  inset: 0;
}
