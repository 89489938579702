@use 'th-common/styles/index';

.menuItemContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menuItemText {
  display: flex;
  align-items: center;
}

.subMenu {
  pointer-events: none;
}

.subMenuItemWrapper {
  pointer-events: auto;
}
