@use 'th-common/styles/index';

.table {
  display: block;
  height: 100%;
  padding-right: index.$padding-xxs;
  overflow: auto;

  &_empty {
    height: 100%;
  }
}
