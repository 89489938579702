@use 'th-common/styles/index';

.mapSettings {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  padding: 0 var(--padding-xxs);
  color: var(--color-on-surf-variant);
}
