.gpsMapPreview {
  @media print {
    max-width: 50rem;
  }
}

.gpsMapData {
  padding: var(--padding-sm);

  &_noGpsData {
    padding: 0;
  }
}
