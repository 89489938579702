@use 'th-common/styles/index';

.grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: index.$spacing-sm;
  align-content: flex-start;
  overflow: auto;
}
