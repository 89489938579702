@use 'th-common/styles/index';

.breadcrumbItem {
  display: flex;
  gap: index.$spacing-xs;
  align-items: center;
  width: auto;
  color: var(--color-on-surf-variant);

  &_last {
    color: var(--color-on-surf);
  }
}

.breadcrumbDropdown {
  background-color: var(--color-surf-container) !important;

  &:hover {
    background-color: var(--color-surf-container-highest) !important;
  }
}
